.display-linebreak {
  white-space: pre-line;
}

.nav li {
  margin-right: 1rem;
}

ul.no-style {
  padding: 0;
  list-style: none;
}

ul.inline li {
  display: inline;
}
.text-center {
  text-align: center;
}
.mg-1 {
  margin: 1rem;
}
.pg-1 {
  padding: 1rem;
}
.pg-2 {
  padding: 2rem;
}
.w-100 {
  width: 100%;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-center {
  align-items: center;
  justify-content: center;
}
.flex-justify-evenly {
  justify-content: space-evenly;
}

button {
  cursor: pointer;
}
button.green {
  background-color: green;
  color: white;
}
button.green:hover {
  background-color: darkgreen;
}
button.blue {
  background-color: #5474b8;
  color: white;
}
button.blue:hover {
  background-color: cornflowerblue;
}

button.rounded {
  border: none;
  border-radius: 5px;
}

button.big {
  padding: 2rem;
  font-size: 1.2rem;
}
form.diagnostic-form label {
  margin-right: 1rem;
}
form.diagnostic-form .fieldset {
  margin-bottom: 1rem;
}
